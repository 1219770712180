
import { toast } from 'react-toastify';
import axios from "axios";
import { GET_FREE_TIMESLOT, GET_SINGLE_TIMESLOT } from 'redux/constants/availabilityConstant';

export const createTimeSlot = (timeSlotData) => async (dispatch) => {
    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "createTimeslot",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "user_id": timeSlotData.user_id,
        "timeslot_date": timeSlotData.timeslot_date,
        "timeslot_time_start": timeSlotData.timeslot_time_start,
        "timeslot_time_end": timeSlotData.timeslot_time_end,
        "timeslot_booked_by": timeSlotData.timeslot_booked_by
    })
    if (data.status === 'Ok') {
        toast.success(data.message);
    } else {
        toast.error(data.message);
    }
}

export const delTimeSlotAct = (timeslot_id) => async (dispatch) => {
    try {
        const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
            "function": "delTimeslot",
            "userId": localStorage.userId,
            "sessionId": localStorage.sessionToken,
            "timeslot_id": timeslot_id,

        })
        if (data.status === 'Ok') {
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
}

export const getFreeTimeSlotAct = () => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getfreeTimeslots",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_FREE_TIMESLOT,
        payload: data.data
    })
}
export const getSingleTimeSlotAct = (timeslot_id) => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getfreeTimeslots",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "timeslot_id": timeslot_id

    })
    dispatch({
        type: GET_SINGLE_TIMESLOT,
        payload: data.data[0] || null
    })
}