import axios from "axios"
//import { toast } from 'react-toastify'; // eslint-disable-line no-use-before-define
import { GET_MECHANIC_SUCCESS, GET_PARTS_SUCCESS } from "redux/constants/commonConstant";

export const getParts = () => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getParts",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_PARTS_SUCCESS,
        payload: data.data
    })
}
export const getMechanic = () => async (dispatch) => {
    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getMechanic",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken
    })
    dispatch({
        type: GET_MECHANIC_SUCCESS,
        payload: data.data
    })
}
