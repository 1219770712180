import axios from 'axios';

const sessionToken = localStorage.getItem('sessionToken');
const userId = localStorage.getItem('userId');

export const fetchUserDetails = () => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "getUser-Details",
        userId: userId,
        sessionId: sessionToken
    });
};


export const fetchUserAddress = () => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "getUser-Adress",
        userId: userId,
        sessionId: sessionToken
    });
};

export const fetchUserBikes = () => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "getUser-Bike",
        userId: userId,
        sessionId: sessionToken
    });
};


export const saveUserBikeOnServer = (payload) => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "saveBike",
        userId: userId,
        sessionId: sessionToken,
        ...payload
    });
};

export const saveUserAddressOnServer = (payload) => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "saveAdresses",
        userId: userId,
        sessionId: sessionToken,
        ...payload
    });
};

export const saveUserDetailsOnServer = (payload) => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "saveDetails",
        userId: userId,
        sessionId: sessionToken,
        ...payload
    });
};

export const delUserDetailsOnServer = (payload) => {
    return axios.post('http://localhost/bleibemobil-backend/dev1/php/accountdel.php', {
        function: "deleteUserRequest",
        user: payload.email
        
    });
};

export const delUserAdressesOnServer = (payload) => {
    return axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        function: "deleteAdresses",
        userId: userId,
        sessionId: sessionToken,
        ...payload
        
    });
};