import axios from "axios"
import { GET_ADMIN_ORDERS, GET_INVOICE_DETAIL, PERSONAL_INVOICE_DETAIL, PREPARE_INVOICE_DETAIL } from "redux/constants/orderConstant"


export const prepareInvoiceDetailAct = (invoice_id, setShowInvoice, setShowBill) => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "prepareInvoice-Details",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "invoice_id": invoice_id
    })
    if (data.status === 'Ok') {
        if (data.data.length) {
            setShowInvoice(false)
            setShowBill(true)
        } else {
            setShowBill(false)
        }
    } else {
        setShowBill(false)
    }
    dispatch({
        type: PREPARE_INVOICE_DETAIL,
        payload: data.data[0] || []
    })
}
export const getPersonalInvoiceDetailAct = (invoice_id, setShowInvoice, setShowBill) => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getpersonal-Invoice-Details",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "invoice_id": invoice_id
    })
    //‚console.log(data)
    if (data.status === 'Ok') {
        if (data.data.length) {
            setShowBill(false)
            setShowInvoice(true)
        } else {
            setShowInvoice(false)
        }
    } else {
        setShowInvoice(false)
    }
    dispatch({
        type: PERSONAL_INVOICE_DETAIL,
        payload: data.data[0] || []
    })
}
export const getInvoiceDetailAct = (invoice_id) => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getInvoice-Details",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "invoice_id": invoice_id
    })

    dispatch({
        type: GET_INVOICE_DETAIL,
        payload: data.data
    })
}

export const getAdminOrders = () => async (dispatch) => {

    const { data } = await axios.post('https://test.bleibemobil.com/dev1/php/client2Server.php', {
        "function": "getAdmin-Appointment",
        "userId": localStorage.userId,
        "sessionId": localStorage.sessionToken,
        "filter": "P"
    })
    dispatch({
        type: GET_ADMIN_ORDERS,
        payload: data.data
    })
}
